import {
    Create,
    Datagrid,
    List,
    Edit,
    Show,
    SimpleShowLayout,
    TextField,
    Pagination,
    BooleanField,
    BooleanInput,
} from "react-admin";
import { SimpleForm, TextInput } from "react-admin";
import { LongDateField, ShortDateField } from "./customFields";
import { BulkActionButtons } from "./common";

const distributorFilters = [<TextInput source="q" label="Search" alwaysOn />];

export const DistributorList = () => (
    <List
        filters={distributorFilters}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        sort={{ field: "created_at", order: "DESC" }}
    >
        <Datagrid rowClick="show" bulkActionButtons={<BulkActionButtons />}>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="ddex_party_id" />
            <BooleanField source="is_ddex_supported" />
            <ShortDateField source="created_at" label="Added on" />
        </Datagrid>
    </List>
);

export const DistributorShow = () => (
    <Show>
        <SimpleShowLayout>
            <h2>Distributor</h2>

            <TextField source="id" />
            <TextField source="name" />
            <TextField source="ddex_party_id" />
            <BooleanField source="is_ddex_supported" />
            <LongDateField source="created_at" label="Added on" />
        </SimpleShowLayout>
    </Show>
);

export const DistributorCreate = () => (
    <Create>
        <SimpleForm>
            <h2>Add Distributor</h2>
            <TextInput source="name" fullWidth />
            <TextInput source="ddex_party_id" fullWidth label="Party id" />
        </SimpleForm>
    </Create>
);

export const DistributorEdit = () => (
    <Edit mutationMode="pessimistic">
        <SimpleForm>
            <h2>Edit Distributor</h2>

            <TextInput source="name" fullWidth />
            <TextInput source="ddex_party_id" fullWidth label="Party id" />
            <BooleanInput source="is_ddex_supported" />
        </SimpleForm>
    </Edit>
);
