import {
    Create,
    Datagrid,
    List,
    Show,
    SimpleShowLayout,
    TextField,
    Pagination,
    SelectField,
    useRecordContext,
    useRedirect,
    ReferenceManyField,
    useCreate,
    useNotify,
    Confirm,
    useRefresh,
    BulkExportButton,
    BulkDeleteButton,
    SelectInput,
    required,
    ReferenceField,
} from "react-admin";
import { SimpleForm, TextInput } from "react-admin";
import { LongDateField, XMLField } from "./customFields";
import { DDEXDryRunStatuses } from "./ddexDryRuns";
import { Button } from "@mui/material";
import { useState, Fragment } from "react";
import { httpClient } from "./dataProvider";
import ActionCheck from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { BulkApi } from "./bulkApi";

const DDEXMessageStatuses = [
    { id: "pending", name: "✉️ Pending" },
    { id: "processing", name: "⏳ Processing" },
    { id: "applied", name: "✅ Applied" },
    { id: "error", name: "❌ Error" },
    { id: "deferred", name: "💤 Deferred" },
];

const ddexMessageFilters = [
    <TextInput source="q" label="Search" alwaysOn />,
    <SelectInput source="status" choices={DDEXMessageStatuses} />,
];

const BulkActionButtons = () => (
    <>
        <BulkApi api="/cms/ddex/messages/apply" confirmLabel="Are you sure you want to apply these messages?" />
        <BulkExportButton />
        <BulkDeleteButton mutationMode="pessimistic" />
    </>
);

export const DDEXMessageList = () => (
    <List
        filters={ddexMessageFilters}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200, 1000]} />}
        sort={{ field: "created_at", order: "DESC" }}
    >
        <Datagrid rowClick="show" bulkActionButtons={<BulkActionButtons />}>
            <TextField source="id" />
            <TextField source="party_name" />
            <TextField source="path" sortable={false} />
            <SelectField source="status" label="Status" choices={DDEXMessageStatuses} sortable={false} />
            <ReferenceField source="distributor_id" reference="distributors" sortable={false} />
            <LongDateField source="created_at" />
        </Datagrid>
    </List>
);

const DeferButton = () => {
    const record = useRecordContext();
    const notify = useNotify();
    const [isLoading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        httpClient(import.meta.env.VITE_SERVER_URL + `/cms/ddex/messages/${record.id}/defer`, {
            method: "POST",
        })
            .then(() => {
                setLoading(false);
                setOpen(false);
                refresh();
            })
            .catch((error) => {
                notify(error.toString(), { type: "error" });
                setLoading(false);
                setOpen(false);
            });
    };

    if (!record) return null;
    return (
        <>
            <Confirm
                isOpen={open}
                loading={isLoading}
                title={`Defer message #${record && record.id}`}
                content="Are you sure you want to defer this message?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                ConfirmIcon={ActionCheck}
                CancelIcon={CancelIcon}
            />
            <Button onClick={handleClick} disabled={isLoading} variant="contained" color="primary">
                Defer
            </Button>
        </>
    );
};

const CreateDryRunButton = () => {
    const record = useRecordContext();
    const notify = useNotify();
    const [create, { isLoading, error }] = useCreate("ddex/dry_runs", { data: { message_id: record.id } });

    if (error) {
        notify(error.toString(), { type: "error" });
    }

    const onClick = () => {
        create();
    };

    if (!record) return null;
    return (
        <Button onClick={onClick} disabled={isLoading} variant="contained" color="primary">
            Dry run
        </Button>
    );
};

const ApplyButton = () => {
    const record = useRecordContext();
    const notify = useNotify();
    const [isLoading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        httpClient(import.meta.env.VITE_SERVER_URL + `/cms/ddex/messages/${record.id}/apply`, {
            method: "POST",
        })
            .then(() => {
                setLoading(false);
                setOpen(false);
                refresh();
            })
            .catch((error) => {
                notify(error.toString(), { type: "error" });
                setLoading(false);
                setOpen(false);
            });
    };

    if (!record) return null;
    return (
        <>
            <Confirm
                isOpen={open}
                loading={isLoading}
                title={`Apply message #${record && record.id}`}
                content="Are you sure you want to apply this message?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                ConfirmIcon={ActionCheck}
                CancelIcon={CancelIcon}
            />
            <Button onClick={handleClick} disabled={isLoading} variant="contained" color="primary">
                Apply
            </Button>
        </>
    );
};

export default function ShowXMLDialog() {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <Button variant="outlined" onClick={handleClickOpen}>
                Show Message XML
            </Button>
            <Dialog
                fullScreen={fullScreen}
                maxWidth="lg"
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Message XML"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <XMLField source="text" />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
export const DDEXMessageShow = () => (
    <Show>
        <SimpleShowLayout>
            <h2>DDEX Message</h2>

            <TextField source="id" />
            <TextField source="party_name" />
            <TextField source="party_id" label="Party ID" />
            <ReferenceField source="distributor_id" reference="distributors" />
            <SelectField source="status" label="Status" choices={DDEXMessageStatuses} />
            <TextField source="path" />
            <ShowXMLDialog />
            <DeferButton />
            <TextField source="logs" component="pre" fontFamily={"monospace"} />
            <LongDateField source="created_at" label="Created at" />
            <CreateDryRunButton />
            <ApplyButton />
            <ReferenceManyField
                label="Dry runs"
                reference="ddex/dry_runs"
                target="message_id"
                sort={{ field: "created_at", order: "DESC" }}
            >
                <Datagrid rowClick="show">
                    <TextField source="id" />
                    <SelectField source="status" label="Status" choices={DDEXDryRunStatuses} />
                    <LongDateField source="created_at" />
                </Datagrid>
            </ReferenceManyField>
        </SimpleShowLayout>
    </Show>
);

export const DDEXMessageCreate = () => (
    <Create>
        <SimpleForm>
            <h2>Add DDEXMessage</h2>

            <TextInput source="path" label="S3 Path" />
        </SimpleForm>
    </Create>
);
