import { AuthProvider, UserIdentity } from "react-admin";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
    getAuth,
    setPersistence,
    signInWithEmailAndPassword,
    browserLocalPersistence,
    signInWithPopup,
    GoogleAuthProvider,
    Auth,
    OAuthProvider,
} from "firebase/auth";
import { setSentryUser } from "./sentry";

const LOGIN_EXPIRE_SECONDS = 12 * 60 * 60;

var firebaseConfig;
if (import.meta.env.MODE === "production") {
    firebaseConfig = {
        apiKey: "AIzaSyAwMv20MvpiyQFSZz3RsbG3X6hSd30uxnA",
        authDomain: "wave-prod-dae70.firebaseapp.com",
        projectId: "wave-prod-dae70",
        storageBucket: "wave-prod-dae70.appspot.com",
        messagingSenderId: "719372490486",
        appId: "1:719372490486:web:885f1ef82ce25abdf33ed7",
        measurementId: "G-4E3ZD9R8CT",
    };
} else if (import.meta.env.MODE === "staging-legacy" || import.meta.env.MODE === "staging") {
    firebaseConfig = {
        apiKey: "AIzaSyAjSoEOw2QDvGnbmTvpkifCLwUBtWN5heU",
        authDomain: "wave-staging-baedb.firebaseapp.com",
        projectId: "wave-staging-baedb",
        storageBucket: "wave-staging-baedb.appspot.com",
        messagingSenderId: "899736006792",
        appId: "1:899736006792:web:0f085237a625730b706f3d",
        measurementId: "G-95D5F67HCL",
    };
} else if (import.meta.env.MODE === "development") {
    firebaseConfig = {
        apiKey: "AIzaSyAkbvLxQHjACHNIfw3X0f5C6PmJQsTv4Yo",
        authDomain: "wave-dev-21daf.firebaseapp.com",
        projectId: "wave-dev-21daf",
        storageBucket: "wave-dev-21daf.appspot.com",
        messagingSenderId: "242157271691",
        appId: "1:242157271691:web:255def0be416d4b4a1b28e",
    };
} else {
    throw new Error("Missing firebase config");
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export type WavePermissions = {
    mode: "admin" | "artist";
};

export const getFirebaseAuth = async (): Promise<Auth> => {
    const auth = getAuth();
    await setPersistence(auth, browserLocalPersistence);
    return auth;
};

export const authProvider: AuthProvider = {
    login: async ({ username, password }) => {
        const auth = await getFirebaseAuth();
        let cred = await signInWithEmailAndPassword(auth, username, password);
        let token = await cred.user.getIdToken();

        await authProvider.registerAndGetPermissions(token);
    },
    loginWithGoogle: async () => {
        const auth = await getFirebaseAuth();
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);
        let token = await result.user.getIdToken();

        await authProvider.registerAndGetPermissions(token);
    },
    loginWithApple: async () => {
        const auth = await getFirebaseAuth();
        const provider = new OAuthProvider("apple.com");
        const result = await signInWithPopup(auth, provider);
        let token = await result.user.getIdToken();

        await authProvider.registerAndGetPermissions(token);
    },
    registerAndGetPermissions: async (token: string): Promise<WavePermissions> => {
        const request = new Request(import.meta.env.VITE_SERVER_URL + "/register", {
            method: "POST",
            headers: new Headers({ Authorization: "Bearer " + token }),
        });
        let r = await fetch(request);
        let user = await r.json();

        if (user.type == "superuser" || user.type == "cms_reader" || user.type == "cms_writer") {
            return { mode: "admin" };
        } else if (user.artist_access?.can_upload_demo) {
            return { mode: "artist" };
        } else {
            throw new Error("Access denied");
        }
    },
    checkError: (error) => {
        console.log(error);
        const status = error?.status;
        if (status === 401) {
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: async () => {
        const auth = await getFirebaseAuth();
        const user = auth.currentUser;

        if (user) {
            if (user.metadata.lastSignInTime === undefined) {
                return Promise.reject();
            }

            let loginTs = new Date(user.metadata.lastSignInTime).getTime();
            var currentTs = new Date().getTime();

            if (currentTs - loginTs > LOGIN_EXPIRE_SECONDS * 1000) {
                return Promise.reject();
            }

            setSentryUser({ id: user.uid, email: user.email ?? undefined });
            return Promise.resolve();
        } else {
            setSentryUser(null);
            return Promise.reject();
        }
    },
    getPermissions: async () => {
        const auth = await getFirebaseAuth();
        const user = auth.currentUser;
        if (!user) {
            return Promise.reject();
        }
        console.log("Get permission");
        return await authProvider.registerAndGetPermissions(await user.getIdToken());
    },
    logout: (params) => {
        const auth = getAuth();

        setSentryUser(null);
        return auth.signOut();
    },
    getIdentity: async () => {
        const auth = await getFirebaseAuth();
        const user = auth.currentUser;

        if (user) {
            const identity: UserIdentity = {
                id: user.uid,
                fullName: user.email || undefined,
                avatar: user.photoURL || undefined,
            };
            return Promise.resolve(identity);
        } else {
            return Promise.reject();
        }
    },
    // authorization
};
